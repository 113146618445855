<template>
  <div>
    <v-sheet class="pa-8">
      <h1 class="mb-5 text-center" v-if="header">{{ $t(header) }}</h1>
      <p v-if="text" align="justify" class="text-center">
        {{ $t(text) }}
      </p>
      <v-row v-if="buttons" justify="center" class="text-center mb-4">
        <v-col v-for="button in buttons" :key="button.slug">
          <v-btn color="accent" :to="button.url">
            <v-icon class="mr-2">{{ button.icon }}</v-icon>
            {{ $t(button.slug) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-divider v-if="header" class="mt-10"></v-divider>
      <v-row justify="center" align="center" class="mt-5">
        <v-icon size="120">mdi-hammer-wrench</v-icon>
      </v-row>
      <p align="justify" class="mt-3 text-center">
        {{ $t("placeholder") }}
      </p>
    </v-sheet>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: false
    },
    header: {
      type: String,
      required: false
    },
    buttons: {
      type: Array,
      default: null
    }
  },
  data: () => ({})
};
</script>
<style scoped>
p {
  text-align: justify;
}
</style>
