<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <disorder-template :heading="$t('relatives')" :previous="previous">
      <placeholder />
    </disorder-template>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DisorderTemplate from "@/components/DisorderTemplate.vue";
import Placeholder from "@/components/Placeholder.vue";

export default {
  name: "relatives",
  components: {
    DisorderTemplate,
    Breadcrumbs,
    Placeholder
  },
  data: () => ({
    previous: {
      slug: "chewing-and-spitting",
      url: "/disorders/chewing-and-spitting"
    }
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("disorders"),
          disabled: false,
          href: "/disorders"
        },
        {
          text: this.$t("relatives"),
          disabled: true
        }
      ];
    }
  }
};
</script>
y
